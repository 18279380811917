
import { defineComponent, ref, onMounted, nextTick, computed } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import { PasswordMeterComponent } from "@/assets/ts/components";

import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "sign-up",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const submitButton = ref<HTMLButtonElement | null>(null);

    const registration = Yup.object().shape({
      nuevaContrasena: Yup.string()
        .required("Es requerido")
        .label("Contraseña"),
      confirmacionNuevaContrasena: Yup.string()
        .required("Es requerido")
        .oneOf(
          [Yup.ref("nuevaContrasena"), null],
          "Confirmación debe coincidir"
        )
        .label("Confirmación de Contraseña"),
    });
    const token = route.params.token;
    const currentUser = computed(() => {
      return store.getters.currentUserPass;
    });
    onMounted(() => {
      nextTick(() => {
        PasswordMeterComponent.bootstrap();
      });
      
    });

    const onSubmitRegister = (values) => {
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      // eslint-disable-next-line
      submitButton.value!.disabled = true;

      // Activate indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      values.token = token;
      console.log(JSON.stringify(values));
      // Dummy delay
      setTimeout(() => {
        // Send login request
        store
          .dispatch(Actions.UPDATE_PASSWORD, values)
          .then(() => {
            Swal.fire({
              text: "Su contraseña ha sido cambiada exitosamente!",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok, vamos!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              router.push({ name: "sign-in" });
            });
          })
          .catch(() => {
            const [error] = Object.keys(store.getters.getAuthErrors);
            Swal.fire({
              text: store.getters.getAuthErrors[error],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Intentar nuevamente",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });

        submitButton.value?.removeAttribute("data-kt-indicator");
        // eslint-disable-next-line
        submitButton.value!.disabled = false;
      }, 1);
    };

    return {
      registration,
      onSubmitRegister,
      submitButton,
      currentUser,token
    };
  },
});
